@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.col-count {
  column-count: 1;
}

 @media (min-width: 768px) { 
  
   .col-count {
    column-count: 2;
    column-gap: 30px;
   }
  
 }
